<template>
  <div>
    <img src="../assets/image/关于我们/资讯中部广告.png" alt="" />
    <div class="content-box">
      <div class="company-profile">
        <h3>公司简介</h3>
        <div class="text-box">
          <span id="span1"></span>
          <span id="span2">COMPANY PROFILE</span>
          <span id="span3"></span>
        </div>
        <p class="intro">
          武汉轩迪是一家创新型的科技公司，本公司依托物联网、大数据、人工智能等数字化技术，遵循"智慧交通" "便捷公交""绿色公交"的行业发展理念，对接公共交通各类资源，打通公共交通营运、安全能耗、线上线下融合、支付等相关数据节点，构建公共交通大数据系统融合决策平台，为公共交通行业提供数字化决策服务，助力公共交通行业达到降本增效、智慧运营的目的.
        </p>
      </div>
      <div class="cooperative-partner">
        <h3>合作伙伴</h3>
        <div class="text-box">
          <span id="span1"></span>
          <span id="span2">COOPERATIVE PARTNER</span>
          <span id="span3"></span>
        </div>
        <div class="cooperative-img">
          <img src="../assets/image/关于我们/支付宝.png" alt="" />
          <img src="../assets/image/关于我们/微信.png" alt="" />
          <img src="../assets/image/关于我们/比亚迪.png" alt="" />
          <img src="../assets/image/关于我们/宇通客车.png" alt="" />
        </div>
        <div class="cooperative-img">
          <img src="../assets/image/关于我们/锐明.png" alt="" />
          <img src="../assets/image/关于我们/海信.png" alt="" />
          <img src="../assets/image/关于我们/美团.png" alt="" />
          <img src="../assets/image/关于我们/中国银联.png" alt="" />
        </div>
        <div class="cooperative-img">
          <img src="../assets/image/关于我们/赋能集团.png" alt="" />
          <img src="../assets/image/关于我们/同程艺龙.png" alt="" />
          <img src="../assets/image/关于我们/湖北楚天云.png" alt="" />
          <img src="../assets/image/关于我们/公用电子.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
<style lang="less" scoped>
img {
  width: 100%;
}
.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h3 {
  text-align: center;
  font-size: 48px;
  color: #333;
  font-family: 'MicrosoftYaHei';
}
.company-profile {
  margin-top: 20px;
}
.text-box {
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  #span2 {
    margin: 0 20px;
    vertical-align: middle;
    font-size: 22px;
    color: #666;
  }
  #span1,
  #span3 {
    display: inline-block;
    width: 106px;
    border-top: 2px solid #3468d2;
  }
}
.intro {
  margin: 28px 220px 60px;
  font-size: 36px;
  color: #333;
  text-indent: 2em;
  line-height: 75px;
}
.cooperative-img {
  float: left;
  width: 100vw;
  text-align: center;
  img {
    width: 22vw;
    height: 220px;
    margin: 1vw;
  }
}
</style>
